import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import AvailTemplate from '../screens/avail'

const AvailPage = (props) => {
  const path = _.get(props, 'pageContext.locale') === 'en'
    ? '/avail/'
    : `${_.get(props, 'pageContext.locale')}/avail/`
  return (
    <Router>
      <AvailTemplate {...props} path={path} />
      <AvailTemplate {...props} path={`${path}:slug`} />
      <AvailTemplate {...props} path={`${path}:id`} />
    </Router>
  )
}

export default AvailPage
