import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import ClaimView from './avail-view'

const ClaimController = (props) => {
  const { slug } = props
  const [loading, setLoading] = useState(true)
  const [claimInsurance, setClaimInsurance] = useState({})
  const { i18n } = useTranslation()
  const getHowToClaim = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'claims-support/how-to-avail',
      params: {
        plan: slug,
        locale: i18n.language,
      },
    })
      .then(({ data }) => {
        setClaimInsurance(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getHowToClaim()
  }, [slug, i18n.language])

  const viewProps = {
    loading,
    data: claimInsurance,
  }

  return <ClaimView {...viewProps} />
}

export default ClaimController
